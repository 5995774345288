import { MAX_AGE } from '@constants';
import type {
  ICartAddParams,
  ICartUpdateParams,
  ICartRemoveParams,
  ICheckoutContactsParams,
  ICheckoutShippingCityParams,
  ICheckoutShippingMethodParams,
  ICheckoutShippingAddressParams,
  ICheckoutShippingAddressesParams,
  ICheckoutPaymentMethodParams,
} from '@types';

export const useCart = () => {
  const { trackAddToCart, trackRemoveFromCart, trackCheckoutShipping, trackCheckoutPayment, trackCheckoutOrder } =
    useAnalytics();

  const localePath = useLocalePath();

  const { isAuth } = storeToRefs(useAuthStore());

  const { user } = storeToRefs(useUserStore());

  const { order, pending: pendingOrder } = storeToRefs(useOrderStore());

  const {
    pending,
    pendingCheckout,
    cart,
    billing,
    shipping,
    shippingMethods,
    shippingMethod,
    paymentMethods,
    paymentMethod,
    comment,
    isAnotherRecipient,
    isCartModal,
    isContactsOpened,
    isShippingOpened,
  } = storeToRefs(useCartStore());

  const openModalCart = (params?: { callback?: Function | null }) => {
    isCartModal.value = true;

    if (typeof params?.callback === 'function') {
      params.callback.apply(null);
    }
  };

  const closeModalCart = (params?: { callback?: Function | null }) => {
    isCartModal.value = false;

    if (typeof params?.callback === 'function') {
      params.callback.apply(null);
    }
  };

  const getCart = async () => {
    pending.value = true;

    try {
      const result = await fetchApiGet('/cart');

      if (result?.cart?.cartId) {
        const _cart = useCookie('_cart', { default: () => undefined, maxAge: MAX_AGE });
        _cart.value = result.cart.cartId;
      }

      cart.value = result?.cart;

      return result;
    } finally {
      pending.value = false;
    }
  };

  const addProduct = async (params: ICartAddParams) => {
    pending.value = true;

    try {
      const result = await fetchApiPost('/cart/add', {
        data: {
          sku: params?.sku,
          quantity: params?.quantity,
          items: params?.items?.map((item) => {
            return {
              data: {
                quantity: item.quantity,
                sku: item.product.sku,
                kit_options: [
                  {
                    block_id: params?.blockId,
                    item_id: item.itemId,
                    kit_id: params?.kitId,
                    position: item.position,
                    product_id: item.productId,
                    qty: item.quantity,
                    selectedCombination: params?.items?.map((item: any) => item.itemId).join('/'),
                  },
                ],
              },
            };
          }),
        },
      });

      trackAddToCart({
        value: params?.price,
        items: (params?.items ?? [params?.item] ?? [])?.map((item) => {
          const attributes = item?.product?.attributes?.items ?? item?.product?.attributes ?? [];
          const brand = attributes?.find((item: any) => item.code === 'publisher')?.selectedOptions?.[0]?.label;
          const author = attributes?.find((item: any) => item.code === 'author')?.selectedOptions?.[0]?.label;

          return {
            item_id: item?.product?.sku,
            item_name: item?.product?.name,
            item_brand: brand,
            item_author: author,
            price: item?.discountedPrice?.value || item?.product?.priceRange?.minimumPrice?.finalPrice?.value,
            quantity: params?.quantity || 1,
          };
        }),
      });

      cart.value = result?.cart;
    } finally {
      pending.value = false;
    }
  };

  const updateProduct = async (params: ICartUpdateParams) => {
    pending.value = true;

    try {
      const result = await fetchApiPut('/cart/update', {
        data: {
          itemId: params?.itemId,
          quantity: params?.quantity,
          items: params?.items?.map((item) => {
            return {
              itemId: item.id,
              quantity: item?.quantity,
            };
          }),
        },
      });

      trackAddToCart({
        value: params?.price,
        items: (params?.items ?? [params?.item] ?? [])?.map((item) => {
          const attributes = item?.product?.attributes?.items ?? item?.product?.attributes ?? [];
          const brand = attributes?.find((item: any) => item.code === 'publisher')?.selectedOptions?.[0]?.label;
          const author = attributes?.find((item: any) => item.code === 'author')?.selectedOptions?.[0]?.label;

          return {
            item_id: item?.product?.sku,
            item_name: item?.product?.name,
            item_brand: brand,
            item_author: author,
            price: item?.discountedPrice?.value || item?.product?.priceRange?.minimumPrice?.finalPrice?.value,
            quantity: params?.quantity,
          };
        }),
      });

      cart.value = result?.cart;
    } finally {
      pending.value = false;
    }
  };

  const removeProduct = async (params: ICartRemoveParams) => {
    pending.value = true;

    try {
      const result = await fetchApiDelete('/cart/remove', {
        data: {
          itemId: params?.itemId,
          items: params?.items?.map((item) => {
            return {
              itemId: item.id,
            };
          }),
        },
      });

      trackRemoveFromCart({
        value: params?.price,
        items: (params?.items ?? [params?.item] ?? [])?.map((item) => {
          const attributes = item?.product?.attributes?.items ?? item?.product?.attributes ?? [];
          const brand = attributes?.find((item: any) => item.code === 'publisher')?.selectedOptions?.[0]?.label;
          const author = attributes?.find((item: any) => item.code === 'author')?.selectedOptions?.[0]?.label;

          return {
            item_id: item?.product?.sku,
            item_name: item?.product?.name,
            item_brand: brand,
            item_author: author,
            price: item?.prices?.price || item?.product?.priceRange?.minimumPrice?.finalPrice?.value,
            quantity: params?.quantity || 1,
          };
        }),
      });

      cart.value = result?.cart;
    } finally {
      pending.value = false;
    }
  };

  const getCheckout = async () => {
    if (!isAuth.value) {
      return null;
    }

    pendingCheckout.value = true;

    try {
      const result = await fetchApiGet('/checkout');

      isContactsOpened.value = true;
      isShippingOpened.value = false;

      if (result?.cart) {
        cart.value = result.cart;
      }

      billing.value = result?.checkout?.billing;
      shipping.value = result?.checkout?.shipping;
      shippingMethods.value = result?.checkout?.shippingMethods;
      shippingMethod.value = result?.checkout?.shippingMethod;
      paymentMethods.value = result?.checkout?.paymentMethods;
      paymentMethod.value = result?.checkout?.paymentMethod;
      comment.value = result?.checkout?.comment;
      isAnotherRecipient.value = result?.checkout?.isAnotherRecipient;

      return result;
    } finally {
      pendingCheckout.value = false;
    }
  };

  const getShippingAddresses = (params: ICheckoutShippingAddressesParams) => {
    try {
      if (params?.carrier === 'novaposhta') {
        return fetchApiPost('/novaposhta', {
          data: {
            search: params?.search,
            cityRef: params?.cityRef,
            isLocker: params?.code === 'to_locker',
          },
        });
      } else if (params?.carrier === 'selfpickup') {
        return fetchApiPost('/bookshops', {
          data: {
            search: params?.search,
            cityId: params?.cityId,
          },
        });
      }
    } catch (error: any) {
      throw createError(error);
    }
  };

  const setContacts = async (params: ICheckoutContactsParams) => {
    pendingCheckout.value = true;

    try {
      const result = await fetchApiPost('/checkout/contacts', {
        data: {
          ...params,
        },
      });

      if (result?.user) {
        user.value = Object.assign({}, user.value, result?.user);
      }

      if (result?.cart) {
        cart.value = result.cart;
      }

      billing.value = result?.checkout?.billing;
      shipping.value = result?.checkout?.shipping;
      shippingMethods.value = result?.checkout?.shippingMethods;
      shippingMethod.value = result?.checkout?.shippingMethod;
      paymentMethods.value = result?.checkout?.paymentMethods;
      paymentMethod.value = result?.checkout?.paymentMethod;
      isAnotherRecipient.value = result?.checkout?.isAnotherRecipient;

      if (result?.checkout?.shippingMethod?.name) {
        trackCheckoutShipping({ shippingTier: result.checkout.shippingMethod.name });
      }

      if (result?.checkout?.paymentMethod?.name) {
        trackCheckoutPayment({ paymentType: result.checkout.paymentMethod.name });
      }
    } finally {
      pendingCheckout.value = false;
    }
  };

  const setShippingCity = async (params: ICheckoutShippingCityParams) => {
    try {
      const result = await fetchApiPost('/checkout/shipping/city', {
        data: {
          ...params,
        },
      });

      if (result?.cart) {
        cart.value = result.cart;
      }

      billing.value = result?.checkout?.billing;
      shipping.value = result?.checkout?.shipping;
      shippingMethods.value = result?.checkout?.shippingMethods;
      shippingMethod.value = result?.checkout?.shippingMethod;
      paymentMethods.value = result?.checkout?.paymentMethods;
      paymentMethod.value = result?.checkout?.paymentMethod;
      isAnotherRecipient.value = result?.checkout?.isAnotherRecipient;

      return result;
    } catch (error: any) {
      throw createError(error);
    }
  };

  const setShippingMethod = async (params: ICheckoutShippingMethodParams) => {
    try {
      const result = await fetchApiPost('/checkout/shipping/method', {
        data: {
          ...params,
        },
      });

      if (result?.cart) {
        cart.value = result.cart;
      }

      shipping.value = result?.checkout?.shipping;
      shippingMethods.value = result?.checkout?.shippingMethods;
      shippingMethod.value = result?.checkout?.shippingMethod;
      paymentMethods.value = result?.checkout?.paymentMethods;
      paymentMethod.value = result?.checkout?.paymentMethod;
      isAnotherRecipient.value = result?.checkout?.isAnotherRecipient;

      if (result?.checkout?.shippingMethod?.name) {
        trackCheckoutShipping({ shippingTier: result.checkout.shippingMethod.name });
      }

      return result;
    } catch (error: any) {
      throw createError(error);
    }
  };

  const setShippingAddress = async (params: ICheckoutShippingAddressParams) => {
    try {
      const result = await fetchApiPost('/checkout/shipping/address', {
        data: {
          ...params,
        },
      });

      if (result?.cart) {
        cart.value = result.cart;
      }

      billing.value = result?.checkout?.billing;
      shipping.value = result?.checkout?.shipping;
      shippingMethods.value = result?.checkout?.shippingMethods;
      shippingMethod.value = result?.checkout?.shippingMethod;
      paymentMethods.value = result?.checkout?.paymentMethods;
      paymentMethod.value = result?.checkout?.paymentMethod;
      isAnotherRecipient.value = result?.checkout?.isAnotherRecipient;

      return result;
    } catch (error: any) {
      throw createError(error);
    }
  };

  const setPaymentMethod = async (params: ICheckoutPaymentMethodParams) => {
    try {
      const result = await fetchApiPost('/checkout/payment/method', {
        data: {
          ...params,
        },
      });

      paymentMethod.value = result?.checkout?.paymentMethod;

      if (result?.checkout?.paymentMethod?.name) {
        trackCheckoutPayment({ paymentType: result.checkout.paymentMethod.name });
      }

      return result;
    } catch (error: any) {
      throw createError(error);
    }
  };

  const setOrder = async () => {
    pendingOrder.value = true;

    try {
      const result = await fetchApiPost('/checkout', {
        data: {
          comment: comment.value,
        },
      });

      await removeCookie('_order');

      const _cart = useCookie('_cart', { default: () => undefined, maxAge: MAX_AGE });
      _cart.value = result?.cart?.cartId;

      const _order = useCookie('_order', { default: () => undefined });
      _order.value = result?.order?.number;

      cart.value = result?.cart;
      order.value = result?.order;

      trackCheckoutOrder();

      if (result?.order?.paymentMethod?.liqPay?.signature) {
        const _liqpay = useCookie('_liqpay', { default: () => undefined });
        _liqpay.value = result.order.paymentMethod.liqPay.signature;

        navigateTo(localePath('/checkout/payment/liqpay'));
      } else {
        navigateTo(localePath('/checkout/success'));
      }

      return result;
    } catch (error: any) {
      pendingOrder.value = false;
      throw createError(error);
    }
  };

  const getReorder = async (number: string) => {
    try {
      const result = await fetchApiPost('/order/reorder', {
        data: {
          number,
        },
      });

      removeCookie('_order');

      cart.value = result?.cart;

      return result;
    } catch (error: any) {
      throw createError(error);
    }
  };

  /**
   * Get title based on shipping method and provided texts.
   * @param {any} shippingMethod - The shipping method object.
   * @param {string} textToLocker - Text for shipping to locker.
   * @param {string} textToWarehouse - Text for shipping to warehouse.
   * @param {string} textBookshop - Text for shipping to bookshop.
   * @returns {string} The title based on the shipping method and provided texts.
   */
  const getTitleByShippingMethod = (
    shippingMethod: any,
    textToLocker: string,
    textToWarehouse: string,
    textBookshop: string
  ): string => {
    return shippingMethod?.carrier === 'novaposhta'
      ? shippingMethod?.code === 'to_locker'
        ? textToLocker
        : textToWarehouse
      : textBookshop;
  };

  return {
    getCart,
    openModalCart,
    closeModalCart,
    addProduct,
    updateProduct,
    removeProduct,
    getCheckout,
    setContacts,
    getShippingAddresses,
    setShippingCity,
    setShippingMethod,
    setShippingAddress,
    setPaymentMethod,
    setOrder,
    getReorder,
    getTitleByShippingMethod,
  };
};
